
import logo from '../../assets/images/gluu-logo@2x.png';
import twitterLogo from '../../assets/images/twitter-white@2x.png';
import linkedInLogo from '../../assets/images/linkedin-white@2x.png';
import telegramLogo from '../../assets/images/telegram-white@2x.png';

const Header = (props) => {
  return (
    <header id="masthead" class="site-header">
			<div class="header-container">
				<div class="site-branding">
					<img src={logo} alt="Gluu logo" />
				</div>

				<nav id="site-navigation" class="main-navigation">
					<div>
						<ul class="grid nav-row">
							<li>
								<a href="https://gluu.agency#develop" class="btn scrollto">
									Development
								</a>
							</li>
							<li>
								<a href="https://gluu.agency#branding" class="btn scrollto">
									Branding
								</a>
							</li>
							<li>
								<a href="https://gluu.agency#contracts" class="btn scrollto">
									Contracts
								</a>
							</li>
							<li>
								<a href="https://gluu.agency#bridging" class="btn scrollto">
									Bridging
								</a>
							</li>
							<li>
								&nbsp;
							</li>
						</ul>
						<ul class="grid nav-row">
							<li>
								&nbsp;
							</li>
							<li>
								<a href="https://gluu.agency#influencer" class="btn scrollto">
									Influencing
								</a>
							</li>
							<li>
								<a href="https://gluu.agency#design" class="btn scrollto">
									Design
								</a>
							</li>
							<li>
								<a href="https://gluu.agency#auditing" class="btn scrollto">
									Audits
								</a>
							</li>
							<li>
								<a href="https://gluu.agency#contactform" class="btn btn--green scrollto">
									Contact
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<div class="socials">
				<a href="https://gluu.agency"><img alt="Twitter Logo" src={twitterLogo} /></a>
				<a href="https://gluu.agency"><img alt="Telegram Logo" src={telegramLogo} /></a>
				<a href="https://gluu.agency"><img alt="Gluu LinkedIn Logo" src={linkedInLogo} /></a>
			</div>
		</header>
  );
}

export default Header;