import './App.css';
import { Container, Row, Col } from 'reactstrap';
import Header from './components/header';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Staking from './pages/staking';

function App() {
  return (
    <Router>
      <Header/>
      <Switch>
        <Route path="/">
          <Staking />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
